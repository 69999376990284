var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ref-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onCreate
    }
  }, [_vm._v("导入链接")]), _c('div', {
    staticClass: "btn btn-create",
    on: {
      "click": _vm.onRefresh
    }
  }, [_vm.isLoading ? [_vm._v("读取中...")] : [_vm._v("刷新")]], 2)]), _c('div', {
    staticClass: "ref-list"
  }, [_c('div', {
    staticClass: "ref-list-item"
  }, [_c('div', {
    staticClass: "s name"
  }, [_vm._v(" 名称 ")]), _c('div', {
    staticClass: "s icon"
  }, [_vm._v(" ICON ")]), _c('div', {
    staticClass: "s label"
  }, [_vm._v(" 邀请链接 ")]), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 邀请人数 ")]), _vm.setting.bot == 'HumanPassBot' ? _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 新刷脸人数 ")]) : _vm._e(), _c('div', {
    staticClass: "s num"
  }, [_vm._v(" 备注 ")]), _c('div', {
    staticClass: "s opt"
  })]), _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      staticClass: "ref-list-item"
    }, [_c('div', {
      staticClass: "s name"
    }, [_vm._v(" " + _vm._s(item.view_remark) + " ")]), _c('div', {
      staticClass: "s icon"
    }, [item.icon ? _c('img', {
      attrs: {
        "src": item.icon,
        "height": "30px"
      }
    }) : _vm._e()]), _c('div', {
      staticClass: "s label"
    }, [_vm._v(" https://t.me/" + _vm._s(_vm.setting.botname) + "/app?startapp=ref_" + _vm._s(item.ref_code) + " ")]), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.ref_count) + " ")]), _vm.setting.bot == 'HumanPassBot' ? _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.first_success) + " ")]) : _vm._e(), _c('div', {
      staticClass: "s num"
    }, [_vm._v(" " + _vm._s(item.remark_tip) + " ")]), _c('div', {
      staticClass: "s opt"
    }, [_c('div', {
      directives: [{
        name: "copy",
        rawName: "v-copy",
        value: `https://t.me/${_vm.setting.botname}/app?startapp=ref_${item.ref_code}`,
        expression: "`https://t.me/${setting.botname}/app?startapp=ref_${ item.ref_code }`"
      }],
      staticClass: "btn"
    }, [_vm._v("复制")]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onModify(item);
        }
      }
    }, [_vm._v("编辑")]), _vm.setting.bot != 'HumanPassBot' ? _c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onRemove(item);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()])]);
  })], 2), _c('pop-u-ref', {
    attrs: {
      "show": _vm.popRefShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popRefShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };